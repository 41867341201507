import { Document, Page, Text, View, Image, Link} from "@react-pdf/renderer";
import PropTypes from "prop-types";
import moment from "moment/moment";
import Logo from '../../../assets/images/logo.png';

import { styles } from "./styles";
import { INDUSTRIES } from "../../../utilities/constants";

const PdfProfile = ({ privacy, data }) => (
  <Document>
          <Page size="A4" style={styles.page}>
              <Image src={Logo} style={{ width: '400px', zIndex: '99', position: 'absolute', opacity: '0.15', top: '35%', left: '15%' }}/>
              <View style={{ ...styles.row, backgroundColor: '#f5f5f5', marginBottom: '10px', padding: '5px 15px' }}>
                  <View style={styles.column}>
                      <Image src={Logo} style={{ width: '100px' }}/>
                  </View>
                  <View style={{ ...styles.column, paddingTop: '5px' }}>
                      <Text style={{ color: '#323232' }}>Resume</Text>
                  </View>
              </View>
              <View style={styles.row}>
                  <View style={styles.profilePhotoColumn}>
                      <Image style={{ width: '160px !important', height: "170px !important" }} src={data?.profilePhoto?.fileUrl} />
                  </View>
                  <View style={styles.personalProfile}>
                      <Text style={styles.sectionTitle}>
                          {data?.firstName
                              ? `${data.firstName?.charAt(0)?.toUpperCase()}${data.firstName?.slice(1)?.toLowerCase()}`
                              : ""}
                          {privacy === "min"
                              ? ""
                              : data?.lastName
                                  ? ` ${data.lastName?.charAt(0)?.toUpperCase()}${data.lastName?.slice(1)?.toLowerCase()}`
                                  : ""}
                      </Text>
                      {data?.dateOfBirth && <Text style={{ fontSize: '12px', color: '#323232' }}>{new Date(data?.dateOfBirth).toLocaleDateString('en-GB')} - {data.nationality}</Text>}
                      <View style={styles.divider} />
                      <Text style={styles.summary}>{data?.summary}</Text>
                  </View>
              </View>
              <View style={styles.row}>
                  <View style={styles.userHistory}>
                      <Text style={styles.sectionTitle}>Work History</Text>
                      <View style={styles.divider}/>
                      <View>
                          <View>
                              {([...(data?.workExperience || [])])
                                  .sort((a, b) => {
                                      if (!a.startDate) return 1;
                                      if (!b.startDate) return -1;

                                      const formats = ["DD/MM/YYYY", moment.ISO_8601];
                                      const dateA = moment(a.startDate, formats, true);
                                      const dateB = moment(b.startDate, formats, true);

                                      if (!dateA.isValid() || !dateB.isValid()) {
                                          return String(b.startDate || "").localeCompare(String(a.startDate || ""));
                                      }

                                      return dateB.valueOf() - dateA.valueOf();
                                  })
                                  .map((i) => (
                                      <View key={i.jobTitle} style={{ marginBottom: '10px', padding: '7px 5px' }}>
                                          <Text style={{ fontSize: '13px', marginBottom: '5px' }}>
                                              {i.jobTitle} {i?.employer && `at ${i.employer}`}{" "}
                                              {i?.city && `- ${i.city.charAt(0).toUpperCase()}${i.city.slice(1)}`}
                                          </Text>
                                          <Text style={{ fontSize: '11px', marginBottom: '5px' }}>
                                              {INDUSTRIES.getLabel(i.industry)}
                                          </Text>
                                          <View style={{ fontSize: '12px', marginBottom: '5px' }}>
                                              <Text style={{ color: '#323232' }}>
                                                  {i?.startDate && moment(i.startDate).isValid()
                                                      ? moment(i?.startDate).format("DD/MM/YYYY")
                                                      : i.startDate} -
                                                  {i?.endDate ? moment(i.endDate).format("DD/MM/YYYY") : "Present"}
                                              </Text>
                                          </View>
                                          <Text style={{ fontSize: '12px', color: '#323232' }}>{i?.description}</Text>
                                          {data?.workExperience && data.workExperience.length > 1 && <View style={styles.minDivider} />}
                                      </View>
                                  ))}
                          </View>
                      </View>
                      <View>
                          <Text style={styles.sectionTitle}>Education</Text>
                          <View style={styles.divider} />
                          <View>
                              {(data?.education || []).map((i) => (
                                  <View key={`${i.instituionName}-${i.degree}`} style={{ padding: '7px 5px' }}>
                                      <Text style={{ fontSize: '13px', marginBottom: '5px' }}>{i?.degree}</Text>
                                      <View>
                                          <Text style={{ fontSize: '12px', color: '#323232', marginBottom: '5px' }}>
                                              {i.institutionName} - {i.city ? `${i.city.charAt(0).toUpperCase()}${i.city.slice(1)}` : ""}
                                          </Text>
                                          <Text style={{ fontSize: '12px', color: '#323232', marginBottom: '5px' }}>{moment(i?.startDate).format("DD/MM/YYYY")} - {i?.endDate ? moment(i.endDate).format("DD/MM/YYYY") : "Present"}</Text>
                                          <Text style={{ fontSize: '12px', color: '#323232' }}>{i?.description}</Text>
                                          {data?.education && data.education.length > 1 && <View style={styles.minDivider} />}
                                      </View>
                                  </View>
                              ))}
                          </View>
                      </View>
                  </View>
                  <View style={styles.contactInfo}>
                      <View style={{ marginBottom: '5px' }}>
                          <View style={{ marginBottom: '5px' }}>
                              <Text style={styles.sectionTitle}>Contact Information</Text>
                              <View style={styles.divider} />
                              <View style={{ padding: '5px 3px'}}>
                                  {privacy !== 'min' && (
                                    <View>
                                        <Text style={styles.subtitle}>
                                            Address: {`${data?.address?.address}, ${data?.address?.zipCode} ${
                                            data?.address?.city
                                                ? data.address.city.charAt(0).toUpperCase() + data.address.city.slice(1)
                                                : ""
                                        }, ${data?.address?.country}`}
                                        </Text>
                                    </View>
                                    )}
                                  {privacy !== 'min' && (
                                    <View>
                                      <Text style={styles.subtitle}>Phone: {data?.phone}</Text>
                                    </View>
                                    )}
                                  <View>
                                      <Text style={styles.subtitle}>Date of birth: {new Date(data?.dateOfBirth).toLocaleDateString('en-GB')}</Text>
                                  </View>
                                  {privacy !== 'min' && (
                                    <View>
                                        <Text style={styles.subtitle}>E-mail: {data?.email}</Text>
                                    </View>
                                  )}
                              </View>
                          </View>
                          {data?.languages && (
                            <View style={{ margin: '7px 0' }}>
                                <Text style={{ ...styles.sectionTitle, ...{ fontSize: "15px" } }}>Languages</Text>
                                <View style={styles.divider} />
                                <View style={{ paddingTop: '7px' }}>
                                    {(data?.languages || []).map((i) => (
                                      <Text key={i.language} style={{ fontSize: '13px', color: '#323232' }}>• {`${i.language} - ${i.languageLevel}`}</Text>
                                    ))}
                                </View>
                            </View>
                          )}
                          {data?.skills && (
                            <View style={{ margin: '7px 0' }}>
                                <Text style={{ ...styles.sectionTitle, ...{ fontSize: "15px" } }}>Skills</Text>
                                <View style={styles.divider} />
                                <View style={{ paddingTop: '7px' }}>
                                    {(data?.skills || []).map((i) => (
                                      <Text key={i} style={{ fontSize: '13px', color: '#323232' }}>• {i}</Text>
                                    ))}
                                </View>
                            </View>
                          )}
                          {data?.hobbies && (
                            <View style={{ margin: '7px 0' }}>
                                <Text style={{ ...styles.sectionTitle, ...{ fontSize: "15px" } }}>Hobbies</Text>
                                <View style={styles.divider} />
                                <View style={{ paddingTop: '7px' }}>
                                    {(data?.hobbies || []).map((i) => (
                                      <Text key={i} style={{ fontSize: '13px', color: '#323232' }}>• {i}</Text>
                                    ))}
                                </View>
                            </View>
                          )}
                          {data?.socialLinks && data.socialLinks.length !== 0 && (
                            <View style={{ margin: '7px 0' }}>
                                <Text style={{ ...styles.sectionTitle, ...{ fontSize: "15px" } }}>Social Media</Text>
                                <View style={styles.divider} />
                                <View style={{ paddingTop: '7px' }}>
                                    {(data?.socialLinks || []).map((i) => (
                                      <Link key={i.socialLinkValue} style={{ fontSize: '13px', color: '#323232' }} src={i.socialLinkValue} href={i.socialLinkValue}>{i.socialLinkName}</Link>
                                    ))}
                                </View>
                            </View>
                          )}
                          {privacy === 'full' && (
                            <View>
                              <Text style={styles.sectionTitle}>Personal Information</Text>
                              <View style={styles.divider} />
                              <View style={{ padding: '5px 3px'}}>
                                  {data?.drivingLicenses && (
                                    <View>
                                        <Text style={styles.subtitle}>Driving Licences: {(data?.drivingLicenses || []).join(', ')}{data?.forkLiftLicense ? ', Forklift' : ''}</Text>
                                    </View>
                                  )}
                                  <View>
                                      <Text style={styles.subtitle}>Shoes: {data?.shoeSize}, Shirt: {data?.shirtSize}, Pants: {data?.pantsSize}</Text>
                                  </View>
                              </View>
                                {(data?.idNumber || data?.bankName || data?.iban || data?.swift) && (
                                    <View>
                                        <Text style={styles.sectionTitle}>Bank Details</Text>
                                        <View style={styles.divider} />
                                        <View style={{ padding: '5px 3px' }}>
                                            {data?.idNumber && (
                                                <View>
                                                    <Text style={styles.subtitle}>ID Number: {data.idNumber}</Text>
                                                </View>
                                            )}
                                            {data?.bankName && (
                                                <View>
                                                    <Text style={styles.subtitle}>Bank Name: {data.bankName}</Text>
                                                </View>
                                            )}
                                            {data?.iban && (
                                                <View>
                                                    <Text style={styles.subtitle}>IBAN: {data.iban}</Text>
                                                </View>
                                            )}
                                            {data?.swift && (
                                                <View>
                                                    <Text style={styles.subtitle}>Swift: {data.swift}</Text>
                                                </View>
                                            )}
                                        </View>
                                    </View>
                                )}
                            </View>
                            )}
                      </View>
                  </View>
                  {data?.certificates && (
                    <View>
                        <Text style={styles.sectionTitle}>Certificates</Text>
                        <View style={{ ...styles.divider, width: '100% !important' }} />
                        <View>
                            {(data?.certificates || []).map((i) => (
                              <View key={`${i.instituionName}-${i.degree}`} style={{ padding: '7px 5px' }}>
                                  <Text style={{ fontSize: '13px', marginBottom: '5px' }}>{i.institutionName} - {i.certificateName}</Text>
                              </View>
                            ))}
                        </View>
                    </View>
                  )}
              </View>
              <View style={{ textAlign: 'center' }}>
                  <View style={{ ...styles.divider, marginBottom: '7px' }} />
                  <Text style={{ ...styles.subtitle, fontSize: '10px' }}>Posrednik24 | st. Franjo Kluz 7/2-5, 1000 Skopje, Macedonia</Text>
                  <Text style={{ ...styles.subtitle, fontSize: '10px' }}>posrednik24.com | +389 79 332 862 | info@posrednik.mk</Text>
              </View>
          </Page>
      </Document>
)

PdfProfile.propTypes = {
    privacy: PropTypes.string.isRequired,
    data: PropTypes.shape({
        id: PropTypes.number.isRequired,
        email: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        dateOfBirth: PropTypes.string,
        profilePhoto: PropTypes.shape({
            fileUrl: PropTypes.string,
        }),
        jobTitle: PropTypes.string,
        summary: PropTypes.string,
        workExperience: PropTypes.arrayOf(PropTypes.shape({
            startDate: PropTypes.string,
            endDate: PropTypes.string,
            jobTitle: PropTypes.string,
            employer: PropTypes.string,
            city: PropTypes.string,
            description: PropTypes.string,
        })),
        education: PropTypes.arrayOf(PropTypes.shape({
            institutionName: PropTypes.string,
            city: PropTypes.string,
            startDate: PropTypes.string,
            endDate: PropTypes.string,
            degree: PropTypes.string,
            description: PropTypes.string,
        })),
        certificates: PropTypes.arrayOf(PropTypes.shape({
            certificateName: PropTypes.string,
            institutionName: PropTypes.string,
        })),
        phone: PropTypes.string,
        socialLinks: PropTypes.arrayOf(PropTypes.shape({
            socialLinkName: PropTypes.string,
            socialLinkValue: PropTypes.string,
        })),
        languages: PropTypes.arrayOf(PropTypes.shape({
            language: PropTypes.string,
            languageLevel: PropTypes.string,
        })),
        skills: PropTypes.arrayOf(PropTypes.string),
        hobbies: PropTypes.string,
        address: PropTypes.shape({
            country: PropTypes.string.isRequired,
            city: PropTypes.string.isRequired,
            zipCode: PropTypes.string.isRequired,
            address: PropTypes.string.isRequired,
        }),
        nationality: PropTypes.string,
        bankName: PropTypes.string,
        idNumber: PropTypes.string,
        swift: PropTypes.string,
        iban: PropTypes.string,
        pantsSize: PropTypes.number,
        shirtSize: PropTypes.string,
        shoeSize: PropTypes.number,
        personalIdentifyingNumber: PropTypes.string,
        userId: PropTypes.number.isRequired,
        drivingLicenses: PropTypes.arrayOf(PropTypes.string),
        forkLiftLicense: PropTypes.bool,
    }).isRequired,
};

export default PdfProfile;
