import { createAsyncThunk } from "@reduxjs/toolkit";

import { makeRequest } from "../../utilities/api";
import { createFormData } from "../../utilities";

export const getProfile = createAsyncThunk("profile/get", async (values, { rejectWithValue }) =>
  makeRequest(`/profile${values?.userId ? `?userId=${values.userId}` : ""}`, "GET", values, rejectWithValue)
);

export const patchProfile = createAsyncThunk("profile/patch", async (values, { rejectWithValue }) => {
  let data = {};
  Object.keys(values).forEach((key) => {
    if ((Array.isArray(data[key]) && data[key].length === 0) || (key.includes("profilePhoto") && !(values[key] instanceof File))) {
      return;
    }
    data[key] = values[key];
  });

  if (values?.socialLinks) {
    data.socialLinks = JSON.stringify(values.socialLinks);
  }
  if (values?.skills) {
    data.skills = JSON.stringify(values.skills);
  }
  if (values?.hobbies) {
    data.hobbies = JSON.stringify(values.hobbies);
  }
  if (values?.languages) {
    data.languages = JSON.stringify(values.languages);
  }
  if (values?.education) {
    data.education = JSON.stringify(values.education);
  }
  if (values?.educationLevel) {
    data.educationLevel = JSON.stringify(values.educationLevel);
  }
  if (values?.certificates) {
    data.certificates = JSON.stringify(values.certificates);
  }
  if (values?.workExperience) {
    data.workExperience = JSON.stringify(values.workExperience);
  }
  // eslint-disable-next-line no-unused-vars
  data = Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null));
  return makeRequest(`/profile/${values.id}`, "PATCH", createFormData(data), rejectWithValue);
});
